<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        color="primary"
        dark
        v-if="!franquia['.key']"
        @click="init"
      >
        <v-icon left>mdi-domain-plus</v-icon>
        Nova Franquia
      </v-btn>
      <v-btn
        v-on="on"
        color="primary"
        v-if="franquia['.key']"
        @click="init"
        fab
        small
      >
        <v-icon dark>mdi-store-edit-outline</v-icon>
      </v-btn>
    </template>
    <v-card class="v-card-profile">
      <v-app-bar color="primary" dark flat>
        <v-toolbar-title class="body-2">
          <!-- <v-spacer></v-spacer> -->
          <span v-if="!franquia.titulo">Nova Franquia</span>
          <span v-else>Editar Franquia</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <v-form v-model="valid" ref="form" lazy-validation>
          <!--v-model="valid" ref="form" lazy-validation -->
          <v-layout row wrap>
            <v-flex lg12 sm12>
              <v-text-field label="Nome da Franquia" v-model="titulo">
              </v-text-field>
            </v-flex>
            <v-flex lg12 sm12>
              <v-select
                v-model="franquia.estado"
                :items="estados"
                item-text="text"
                item-value="value"
                label="Estado"
                persistent-hint
                single-line
              ></v-select>
            </v-flex>
            <v-flex lg12 sm12>
              <v-select
                v-model="franquia.cidade"
                :items="cidades"
                item-text="text"
                item-value="value"
                label="Cidade"
                persistent-hint
                single-line
              ></v-select>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-3">
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click.native="cancelar()"
          >Cancelar</v-btn
        >
        <v-btn color="primary" @click.native="salvar()" v-if="!franquia['.key']">Inserir</v-btn>
        <v-btn color="primary" @click.native="atualizar()" v-else>Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import toastr from "toastr";
import firebase from "firebase/app";
import brasil from "@/assets/brasil.json";
// Utilities
import { mapState } from "vuex";
export default {
  name: "CursoModal",
  data() {
    return {
      dialog: false,
      valid: true,
      brasil,
      titulo: "",
      estados: [
        { value: null, text: "Selecione um estado" },
        { value: "AC", text: "Acre" },
        { value: "AL", text: "Alagoas" },
        { value: "AP", text: "Amapá" },
        { value: "AM", text: "Amazonas" },
        { value: "BA", text: "Bahia" },
        { value: "CE", text: "Ceará" },
        { value: "DF", text: "Distrito Federal" },
        { value: "ES", text: "Espírito Santo" },
        { value: "GO", text: "Goiás" },
        { value: "MA", text: "Maranhão" },
        { value: "MT", text: "Mato Grosso" },
        { value: "MS", text: "Mato Grosso do Sul" },
        { value: "MG", text: "Minas Gerais" },
        { value: "PA", text: "Pará" },
        { value: "PB", text: "Paraíba" },
        { value: "PR", text: "Paraná" },
        { value: "PE", text: "Pernambuco" },
        { value: "PI", text: "Piauí" },
        { value: "RJ", text: "Rio de Janeiro" },
        { value: "RN", text: "Rio Grande do Norte" },
        { value: "RS", text: "Rio Grande do Sul" },
        { value: "RO", text: "Rondônia" },
        { value: "RR", text: "Roraima" },
        { value: "SC", text: "Santa Catarina" },
        { value: "SP", text: "São Paulo" },
        { value: "SE", text: "Sergipe" },
        { value: "TO", text: "Tocantins" },
      ],
    };
  },
  props: {
    franquia: {
      type: Object,
      default: () => ({
        estado: "",
        titulo: "",
        cidade: "",
      }),
    },
  },
  computed: {
    ...mapState(["userEdit"]),
    cidades() {
      if (this.franquia.estado) {
        return brasil[this.franquia.estado].cidades;
      } else {
        return [];
      }
    },
  },
  methods: {
    salvar() {
      let obj = {};

      obj.titulo = this.titulo;
      obj.estado = this.franquia.estado;
      obj.cidade = this.franquia.cidade;

      const ref = "franquias/";
      firebase
        .database()
        .ref(ref)
        .push(obj)
        .then(() => {
          // Update successful.
          let msg = "Franquia Inserida com sucesso!";
          toastr.success(msg, "Confabular");
          this.dialog = false;
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.message,
            "Confabular"
          );
        });

      //Verificar a possibilidade de atualizar o curso nos usuários matriculados
    },
    atualizar() {
      let obj = this.franquia;
      obj.titulo = this.titulo;
      const ref = "franquias/" + obj[".key"];
      firebase
        .database()
        .ref(ref)
        .update(obj)
        .then(() => {
          // Update successful.
          let msg = "Franquia atualizada com sucesso!";
          toastr.success(msg, "Confabular");
          this.dialog = false;
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.message,
            "Confabular"
          );
        });

      //Verificar a possibilidade de atualizar o curso nos usuários matriculados
    },
    cancelar() {
      this.dialog = false;
    },
    init() {
      this.titulo = this.franquia.titulo
      // console.log(this.franquia);
    },
  },
  // watch: {
  //   "franquia.estado": function () {
  //     // console.log(this.curso);
  //     this.cidades = brasil[this.franquia.estado].cidades;
  //   },
  // },
  created() {
    // console.log(this.userEdit)
  },
  mounted() {
    // console.log(process.env.VUE_APP_ROOT_API)
  },
};
</script>
